<template>
  <div style="width: 100%; height: calc(100svh - 10px)">
    <div class="pa-2 row-flex-align-space">
      <v-switch
        v-model="filterUnreadMessages"
        :label="str['filter_conversations_with_new_messages']"
        color="secondary"
        class="mt-0 pa-0"
        style="width: fit-content"
        hide-details
        @change="onChangeFilterUnreadMessages"
      />
      <div v-if="close" class="pl-2">
        <v-icon color="default" class="cursor-hover" @click="close">mdi-close-circle</v-icon>
      </div>
    </div>
    <div ref="talkjs" style="width: 100%; height: calc(100% - 40px)">
      <i class="text-center">
        {{ str['processing'] }}
      </i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Talk from 'talkjs'
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'Talksjs',
  props: {
    refresh: Function,
    open: Function,
    close: Function,
    openClientDetails: Function,
  },
  data() {
    const user = Utils.getUser()
    const ptTag = Object.keys(PTS_IDS)
      .find((k) => PTS_IDS[k] === user.pt_id)
      ?.toLowerCase()
    return {
      user: user,
      str: window.strings,
      ptTag: ptTag,
      isEmployee: Utils.isEmployee(user.type),
      employeeIncludedInChat:
        user && user.configurations && user.configurations.employees && user.configurations.employees.include_in_chat ? true : false,
      userId: (axios.defaults.dev ? 'dev' : 'prod') + '_' + ptTag + '_' + user.pt_id,
      filterUnreadMessages: false,
      inbox: null,
      appId: null,
      token: null,
      isMobileScreen: window.innerWidth < 800,
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    init: async function () {
      const self = this
      await Talk.ready

      const id =
        this.isEmployee && this.employeeIncludedInChat
          ? (axios.defaults.dev ? 'dev' : 'prod') + '_' + this.ptTag + '_' + this.user.id
          : this.userId
      Api.getTalkjsToken(
        {
          id: id,
        },
        function (response) {
          if (response.success) {
            self.appId = response.data.app_id
            self.token = response.data.token
            self.draw()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    draw: function () {
      const filterEmployee =
        this.isEmployee &&
        this.user &&
        this.user.configurations &&
        this.user.configurations.employees &&
        this.user.configurations.employees.assign_clients
          ? true
          : false
      window.talkjsPtUser = this.isEmployee
        ? new Talk.User(this.userId)
        : new Talk.User({
            id: this.userId,
            name: this.user.name,
            welcomeMessage: null,
            locale: 'pt-BR',
            role: this.ptTag + '_admin',
          })
      window.talkjsPtEmployee =
        this.isEmployee && this.employeeIncludedInChat
          ? new Talk.User({
              id: (axios.defaults.dev ? 'dev' : 'prod') + '_' + this.ptTag + '_' + this.user.id,
              name: this.user.name,
              welcomeMessage: null,
              locale: 'pt-BR',
              role: this.ptTag + '_admin',
            })
          : null
      window.talkjsSession = new Talk.Session({
        appId: this.appId,
        me: this.isEmployee && this.employeeIncludedInChat ? window.talkjsPtEmployee : window.talkjsPtUser,
        locale: 'pt-BR',
        token: this.token,
      })

      window.talkjsSession.unreads.onChange(async (conversations) => {
        let totalUnreadMessages = 0
        if (filterEmployee) {
          conversations = conversations.filter((c) => {
            return c.conversation && c.conversation.custom && c.conversation.custom['employee_' + this.user.id]
          })
        }
        conversations.forEach((conversation) => {
          totalUnreadMessages += conversation.unreadMessageCount
        })

        this.refresh(totalUnreadMessages)
      })

      this.inbox = window.talkjsSession.createInbox({
        selected: null,
        locale: 'pt-BR',
      })

      this.inbox.onCustomConversationAction((event) => {
        if (event.action === 'open_client') {
          try {
            const clientId = event.conversation.subject.split(' - ')[0]
            if (clientId) {
              this.$isLoading(true)
              Api.getUserWithId(
                {
                  id: clientId,
                },
                (response) => {
                  this.$isLoading(false)
                  if (response.success && response.data[0]) {
                    if (this.isMobileScreen) {
                      this.inbox.select(null)
                      this.close()
                    }
                    this.openClientDetails(response.data[0])
                  } else {
                    this.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                  }
                },
              )
            }
          } catch {}
        }
      })

      const data = {}
      if (filterEmployee) {
        data.custom = {
          ['employee_' + this.user.id]: 'exists',
        }
      }
      if (this.filterUnreadMessages) {
        data.hasUnreadMessages = true
      }
      this.inbox.mount(this.$refs.talkjs)
      this.inbox.setFeedFilter(data)
    },
    openClient: function (clientDbId) {
      const self = this
      this.$isLoading(true)
      Api.getUserWithDbId(
        {
          id: clientDbId,
          fields: ['id', 'name', 'chat_token', 'employee1', 'employee2'],
        },
        function (response) {
          self.$isLoading(false)
          if (response.success && response.data && response.data[0]) {
            const clientData = response.data[0]
            const client = new Talk.User({
              id: (axios.defaults.dev ? 'dev' : 'prod') + '_' + self.ptTag + '_' + clientData.id,
              name: clientData.id + ' - ' + clientData.name,
              locale: 'pt-BR',
              role: self.ptTag + '_client',
            })
            const conversation = window.talkjsSession.getOrCreateConversation(Talk.oneOnOneId(window.talkjsPtUser, client))
            const custom = {
              customer_token: clientData.chat_token ? clientData.chat_token : '',
            }
            if (clientData.employee1) {
              custom['employee_' + clientData.employee1] = 'true'
            }
            if (clientData.employee2) {
              custom['employee_' + clientData.employee2] = 'true'
            }
            conversation.setAttributes({
              custom: custom,
              subject: clientData.id + ' - ' + clientData.name,
            })
            conversation.setParticipant(window.talkjsPtUser)
            conversation.setParticipant(client)
            if (window.talkjsPtEmployee) {
              conversation.setParticipant(window.talkjsPtEmployee)
            }
            self.inbox.select(conversation)
            self.open()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    changeStatus: function (value) {
      this.inbox.setPresence({ visible: value })
    },
    onChangeFilterUnreadMessages: function () {
      this.inbox.destroy()
      this.draw()
    },
  },
}
</script>
